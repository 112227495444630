// eslint-disable-next-line import/no-extraneous-dependencies
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  apiInstance,
  axiosInstance,
  axiosProspectInstance,
} from 'settings/AxiosSetting';

export const useGetTechStack = () =>
  useMutation((data: { linkedin_url: string; email_address: string }) => {
    return axiosInstance.post('v2/techstack', data);
  });

export const useGetUserInfo = () =>
  useMutation((data: { linkedin_url: string; email_address?: string }) => {
    return axiosProspectInstance.post('prospect-data', data);
  });

export const useGetProspectData = () =>
  useMutation((data: { linkedin_url: string; email_address: string }) => {
    return axiosInstance.post('v2/prospect-data', data);
  });

export const useRetryProspectLoading = () =>
  useMutation((data: { linkedin_url: string; email_address: string }) => {
    return axiosInstance.post('v2/prospect-retry', data);
  });

export const useGetCreditPoints = ({ onError }) => {
  return useQuery({
    queryKey: ['getCredits'],
    queryFn: () => {
      return apiInstance.get('credits/user');
    },
    onError: (err: any) => {
      if (err?.response?.status === 403) {
        onError();
      }
    },
  });
};

export const useGetUserVerifyInfo = () => {
  return useQuery({
    queryKey: ['getVerifyUser'],
    queryFn: () => {
      return apiInstance.get('auth/verify');
    },
  });
};

export const useUpdateCreditPoints = () => {
  return useMutation((data: { used_for: string; credit_used: number }) => {
    return apiInstance.post('credits/user', data);
  });
};

export const useUnlockCredits = () =>
  useMutation((data: { prospect_id?: string }) => {
    return axiosProspectInstance.post('update-credit', data);
  });
export const useRefetchProspectData = () =>
  useMutation((data: { linkedin_url?: string }) => {
    return axiosProspectInstance.post('prospect-data-refetch', data);
  });
export const useGetUserProspectProspectInsightsIcebreakers = () =>
  useMutation((data: { prospect_id?: string }) => {
    return axiosProspectInstance.post(
      'prospect/prospect-insights-icebreakers',
      data,
    );
  });
export const useGetUserProspectOrganizationInsightsIcebreakers = () =>
  useMutation((data: { prospect_id?: string }) => {
    return axiosProspectInstance.post(
      'prospect/organization-insights-icebreakers',
      data,
    );
  });
export const useGetUserProspectDiscProfile = () =>
  useMutation((data: { prospect_id?: string }) => {
    return axiosProspectInstance.post('prospect/prospect-disc-profile', data);
  });

export const useAddProspect = () => {
  return useMutation((data: { prospect_ids: string | any }) => {
    return axiosProspectInstance.post('add-as-prospect', data);
  });
};
