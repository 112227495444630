import { useContext, useMemo, useState } from 'react';
import { ArrowRightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { DISCLabels } from 'consts/label/en-US/pages/contact';
import { CommonLabels } from 'consts/label';
import Accordion from 'components/Accordion';
import SummarizeCard from 'components/SummarizeCard';
import Text from 'components/Text';
import Flex from 'components/Flex';
import { Progress, Tag, Tooltip } from 'components/Ant';
import { Icon } from 'components/Icon';
import FailsEmptyState from 'components/FailsEmptyState';
import { InsightsContext } from './insights.context';

const DOMINANCE = 'Dominance';
const INFLUENCE = 'Influence';
const STEADINESS = 'Steadiness';
const CONSCIENTIOUSNESS = 'Conscientiousness';
const DiscIcon = ({ data }) => {
  return (
    <svg
      width="50"
      height="51"
      viewBox="0 0 50 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Steady S  */}

      <mask id="path-1-inside-1_1849_50901" fill="white">
        <path d="M50 26.0596C50 29.231 49.3753 32.3713 48.1617 35.3012C46.9481 38.2312 45.1692 40.8934 42.9268 43.1359C40.6843 45.3784 38.0221 47.1572 35.0921 48.3709C32.1622 49.5845 29.0219 50.2091 25.8505 50.2091L25.8505 26.0596H50Z" />
      </mask>
      <path
        d="M50 26.0596C50 29.231 49.3753 32.3713 48.1617 35.3012C46.9481 38.2312 45.1692 40.8934 42.9268 43.1359C40.6843 45.3784 38.0221 47.1572 35.0921 48.3709C32.1622 49.5845 29.0219 50.2091 25.8505 50.2091L25.8505 26.0596H50Z"
        stroke="#00AD4C"
        fill={data.includes(STEADINESS) ? '#00AD4C' : ''}
        strokeWidth="3"
        mask="url(#path-1-inside-1_1849_50901)"
      />
      {/* Conscientious C */}

      <mask id="path-2-inside-2_1849_50901" fill="white">
        <path d="M24.1495 50.2091C20.9781 50.2091 17.8378 49.5845 14.9079 48.3709C11.9779 47.1572 9.31569 45.3784 7.07321 43.1359C4.83072 40.8934 3.05188 38.2312 1.83826 35.3012C0.624632 32.3713 -1.26383e-05 29.231 -1.24997e-05 26.0596L24.1495 26.0596L24.1495 50.2091Z" />
      </mask>
      <path
        d="M24.1495 50.2091C20.9781 50.2091 17.8378 49.5845 14.9079 48.3709C11.9779 47.1572 9.31569 45.3784 7.07321 43.1359C4.83072 40.8934 3.05188 38.2312 1.83826 35.3012C0.624632 32.3713 -1.26383e-05 29.231 -1.24997e-05 26.0596L24.1495 26.0596L24.1495 50.2091Z"
        fill={data.includes(CONSCIENTIOUSNESS) ? '#00A0D0' : ''}
        stroke="#00A0D0"
        strokeWidth="3"
        mask="url(#path-2-inside-2_1849_50901)"
      />
      {/* Dominant D  */}

      <mask id="path-3-inside-3_1849_50901" fill="white">
        <path d="M-9.33288e-06 24.3603C-9.05563e-06 21.1889 0.624636 18.0486 1.83826 15.1187C3.05189 12.1887 4.83073 9.5265 7.07321 7.28402C9.3157 5.04153 11.9779 3.26269 14.9079 2.04907C17.8378 0.835443 20.9781 0.210798 24.1495 0.210798L24.1495 24.3603L-9.33288e-06 24.3603Z" />
      </mask>
      <path
        d="M-9.33288e-06 24.3603C-9.05563e-06 21.1889 0.624636 18.0486 1.83826 15.1187C3.05189 12.1887 4.83073 9.5265 7.07321 7.28402C9.3157 5.04153 11.9779 3.26269 14.9079 2.04907C17.8378 0.835443 20.9781 0.210798 24.1495 0.210798L24.1495 24.3603L-9.33288e-06 24.3603Z"
        fill={data.includes(DOMINANCE) ? '#CD3741' : ''}
        stroke="#CD3741"
        strokeWidth="3"
        mask="url(#path-3-inside-3_1849_50901)"
      />
      {/* Influentital I  */}
      <mask id="path-4-inside-3_1849_50901" fill="white">
        <path d="M25.8505 0.210799C29.0219 0.210799 32.1622 0.835444 35.0921 2.04907C38.0221 3.26269 40.6843 5.04153 42.9268 7.28402C45.1692 9.52651 46.9481 12.1887 48.1617 15.1187C49.3753 18.0486 50 21.1889 50 24.3603L25.8505 24.3603L25.8505 0.210799Z" />
      </mask>
      <path
        d="M25.8505 0.210799C29.0219 0.210799 32.1622 0.835444 35.0921 2.04907C38.0221 3.26269 40.6843 5.04153 42.9268 7.28402C45.1692 9.52651 46.9481 12.1887 48.1617 15.1187C49.3753 18.0486 50 21.1889 50 24.3603L25.8505 24.3603L25.8505 0.210799Z"
        stroke="#F3CB24"
        strokeWidth="3"
        fill={data.includes(INFLUENCE) ? '#F3CB24' : ''}
        mask="url(#path-4-inside-3_1849_50901)"
      />
      {/* Dominant D letter */}
      <path
        d="M13.658 18H11.5955V12.1818H13.6751C14.2603 12.1818 14.7641 12.2983 15.1864 12.5312C15.6088 12.7623 15.9336 13.0947 16.1609 13.5284C16.39 13.9621 16.5046 14.4811 16.5046 15.0852C16.5046 15.6913 16.39 16.2121 16.1609 16.6477C15.9336 17.0833 15.6069 17.4176 15.1808 17.6506C14.7565 17.8835 14.2489 18 13.658 18ZM12.8256 16.946H13.6069C13.9705 16.946 14.2764 16.8816 14.5245 16.7528C14.7745 16.6222 14.962 16.4205 15.087 16.1477C15.2139 15.8731 15.2773 15.5189 15.2773 15.0852C15.2773 14.6553 15.2139 14.304 15.087 14.0312C14.962 13.7585 14.7754 13.5578 14.5273 13.429C14.2792 13.3002 13.9734 13.2358 13.6097 13.2358H12.8256V16.946Z"
        opacity={data.includes(DOMINANCE) ? 1 : 0.2}
        fill={data.includes(DOMINANCE) ? 'white' : 'black'}
      />
      {/* Influentital I letter */}
      <path
        d="M37.1147 12.1818V18H35.8846V12.1818H37.1147Z"
        opacity={data.includes(INFLUENCE) ? 1 : 0.2}
        fill={data.includes(INFLUENCE) ? 'white' : 'black'}
      />
      {/* Conscientious Letter C */}
      <path
        d="M17.1314 35.2188H15.8871C15.8643 35.0578 15.8179 34.9148 15.7479 34.7898C15.6778 34.6629 15.5878 34.5549 15.478 34.4659C15.3681 34.3769 15.2412 34.3087 15.0973 34.2614C14.9553 34.214 14.8009 34.1903 14.6342 34.1903C14.3331 34.1903 14.0708 34.2652 13.8473 34.4148C13.6238 34.5625 13.4505 34.7784 13.3274 35.0625C13.2043 35.3447 13.1428 35.6875 13.1428 36.0909C13.1428 36.5057 13.2043 36.8542 13.3274 37.1364C13.4524 37.4186 13.6267 37.6316 13.8501 37.7756C14.0736 37.9195 14.3321 37.9915 14.6257 37.9915C14.7905 37.9915 14.9429 37.9697 15.0831 37.9261C15.2251 37.8826 15.3511 37.8191 15.4609 37.7358C15.5708 37.6506 15.6617 37.5473 15.7337 37.4261C15.8075 37.3049 15.8587 37.1667 15.8871 37.0114L17.1314 37.017C17.0992 37.2841 17.0187 37.5417 16.8899 37.7898C16.763 38.036 16.5916 38.2566 16.3757 38.4517C16.1617 38.6449 15.906 38.7983 15.6087 38.9119C15.3132 39.0237 14.9789 39.0795 14.6058 39.0795C14.0869 39.0795 13.6229 38.9621 13.2138 38.7273C12.8066 38.4924 12.4846 38.1525 12.2479 37.7074C12.013 37.2623 11.8956 36.7235 11.8956 36.0909C11.8956 35.4564 12.0149 34.9167 12.2536 34.4716C12.4922 34.0265 12.8161 33.6875 13.2251 33.4545C13.6342 33.2197 14.0945 33.1023 14.6058 33.1023C14.9429 33.1023 15.2554 33.1496 15.5433 33.2443C15.8331 33.339 16.0897 33.4773 16.3132 33.6591C16.5367 33.839 16.7185 34.0597 16.8587 34.321C17.0007 34.5824 17.0916 34.8816 17.1314 35.2188Z"
        opacity={data.includes(CONSCIENTIOUSNESS) ? 1 : 0.2}
        fill={data.includes(CONSCIENTIOUSNESS) ? 'white' : 'black'}
      />
      {/* Steady S letter */}
      <path
        d="M37.0408 34.8551C37.0181 34.6259 36.9206 34.4479 36.7482 34.321C36.5759 34.1941 36.342 34.1307 36.0465 34.1307C35.8458 34.1307 35.6763 34.1591 35.538 34.2159C35.3997 34.2708 35.2937 34.3475 35.2198 34.446C35.1478 34.5445 35.1119 34.6562 35.1119 34.7812C35.1081 34.8854 35.1299 34.9763 35.1772 35.054C35.2264 35.1316 35.2937 35.1989 35.3789 35.2557C35.4641 35.3106 35.5626 35.3589 35.6744 35.4006C35.7861 35.4403 35.9054 35.4744 36.0323 35.5028L36.555 35.6278C36.8088 35.6847 37.0418 35.7604 37.2539 35.8551C37.466 35.9498 37.6497 36.0663 37.805 36.2045C37.9603 36.3428 38.0806 36.5057 38.1658 36.6932C38.253 36.8807 38.2975 37.0956 38.2994 37.3381C38.2975 37.6941 38.2066 38.0028 38.0266 38.2642C37.8486 38.5237 37.591 38.7254 37.2539 38.8693C36.9187 39.0114 36.5143 39.0824 36.0408 39.0824C35.5711 39.0824 35.1621 39.0104 34.8136 38.8665C34.467 38.7225 34.1961 38.5095 34.0011 38.2273C33.8079 37.9432 33.7066 37.5919 33.6971 37.1733H34.8874C34.9007 37.3684 34.9566 37.5312 35.055 37.6619C35.1554 37.7907 35.2889 37.8883 35.4556 37.9545C35.6242 38.0189 35.8145 38.0511 36.0266 38.0511C36.235 38.0511 36.4158 38.0208 36.5692 37.9602C36.7246 37.8996 36.8448 37.8153 36.93 37.7074C37.0153 37.5994 37.0579 37.4754 37.0579 37.3352C37.0579 37.2045 37.0191 37.0947 36.9414 37.0057C36.8656 36.9167 36.7539 36.8409 36.6062 36.7784C36.4603 36.7159 36.2814 36.6591 36.0692 36.608L35.4357 36.4489C34.9452 36.3295 34.5579 36.143 34.2738 35.8892C33.9897 35.6354 33.8486 35.2936 33.8505 34.8636C33.8486 34.5114 33.9424 34.2036 34.1317 33.9403C34.323 33.6771 34.5853 33.4716 34.9187 33.3239C35.252 33.1761 35.6308 33.1023 36.055 33.1023C36.4869 33.1023 36.8638 33.1761 37.1857 33.3239C37.5096 33.4716 37.7615 33.6771 37.9414 33.9403C38.1213 34.2036 38.2141 34.5085 38.2198 34.8551H37.0408Z"
        opacity={data.includes(STEADINESS) ? 1 : 0.2}
        fill={data.includes(STEADINESS) ? 'white' : 'black'}
      />
    </svg>
  );
};

const AnalysisItem = ({ title, description, isLast = false }) => {
  const color = useMemo(() => {
    switch (title) {
      case DOMINANCE:
        return 'red';
      case CONSCIENTIOUSNESS:
        return 'blue';
      case INFLUENCE:
        return 'yellow';
      case STEADINESS:
        return 'green';
      default:
        return '';
    }
  }, [title]);
  return (
    <Flex
      direction="col"
      border={` ${isLast ? '' : 'border-b border-solid border-0'}`}
      borderColor="border-gray-100"
      padding={` ${isLast ? '' : 'pb-4'}`}
      margin={` ${isLast ? '' : 'mb-4'} `}
    >
      <Text variant="span">
        <Tag
          className={` ${color === 'red' ? 'text-red-800 bg-red-100' : ''} ${
            color === 'yellow' ? 'text-yellow-800 bg-yellow-100' : ''
          } ${color === 'blue' ? 'text-blue-800 bg-blue-100' : ''} ${
            color === 'green' ? 'text-green-800 bg-green-100' : ''
          } font-medium rounded-3xl px-2.5 py-0.5 whitespace-pre-line`}
          bordered={false}
        >
          {title}
        </Tag>
      </Text>
      <Flex
        margin="mt-2.5"
        padding="p-4"
        border="border border-solid"
        borderColor=" border-[#EBEBEB]"
        backgroundColor="bg-white"
        rounded="rounded-md"
      >
        <Text variant="psm" color=" text-textPrimary">
          {description}
        </Text>
      </Flex>
    </Flex>
  );
};

const CardContainer = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Flex
      padding="p-4"
      backgroundColor="bg-white"
      rounded="rounded-md"
      width="w-full"
      direction="col"
      className=" shadow-card"
    >
      <Accordion title={title} isOpen={isOpen} setIsOpen={setIsOpen} />
      {isOpen && children}
    </Flex>
  );
};

const AnalysisProgressContainer = ({
  title,
  personalityParameter = '',
  parameterTooltip = '',
}) => {
  return (
    <Flex direction="col" width="w-full">
      <Flex justify="between" alignItems="center">
        <Text variant="psm" weight={500} color=" text-textPrimary">
          {title}
        </Text>
        <Text
          variant="pxs"
          position="center"
          color="text-secondary"
          weight={400}
        >
          {personalityParameter}
          <Tooltip
            title={
              <Text variant="span" className=" text-xs">
                {personalityParameter && (
                  <Text
                    variant="span"
                    weight={600}
                    className=" text-xs"
                    margin="mr-1"
                  >
                    {personalityParameter}:
                  </Text>
                )}
                {parameterTooltip}
              </Text>
            }
          >
            <InfoCircleOutlined className="ml-1 cursor-pointer" />
          </Tooltip>
        </Text>
      </Flex>
      <Progress
        percent={
          personalityParameter.toLowerCase() === 'low'
            ? 30
            : personalityParameter.toLowerCase() === 'medium'
            ? 60
            : 100
        }
        showInfo={false}
        strokeColor={`${
          personalityParameter.toLowerCase() === 'low' ? '#ef4444' : ''
        }
        ${personalityParameter.toLowerCase() === 'medium' ? '#F59E0B' : ''}
        ${personalityParameter.toLowerCase() === 'high' ? '#10B981' : ''}

        `}
      />
    </Flex>
  );
};

const DiscInsights = () => {
  const { fetchDisc, userInfoData, isLoadingDisc, discData } =
    useContext(InsightsContext);
  const discTitleForm = useMemo(() => {
    if (discData) {
      return [
        discData?.disc_profile?.trait1?.name,
        discData?.disc_profile?.trait2?.name,
      ]
        .filter((data) => data)
        .join(' • ');
    }
    return '';
  }, [discData]);
  const discTitle = useMemo(() => {
    if (discData) {
      const isDominance: boolean =
        discData?.disc_profile?.trait1?.name === DOMINANCE ||
        discData?.disc_profile?.trait2?.name === DOMINANCE;
      const isInfluence =
        discData?.disc_profile?.trait1?.name === INFLUENCE ||
        discData?.disc_profile?.trait2?.name === INFLUENCE;
      const isConscientiousness =
        discData?.disc_profile?.trait1?.name === CONSCIENTIOUSNESS ||
        discData?.disc_profile?.trait2?.name === CONSCIENTIOUSNESS;
      const isSteadiness =
        discData?.disc_profile?.trait1?.name === STEADINESS ||
        discData?.disc_profile?.trait2?.name === STEADINESS;

      if (
        !isDominance &&
        !isInfluence &&
        !isConscientiousness &&
        isSteadiness
      ) {
        return 'Supporter';
      }
      if (
        !isDominance &&
        !isInfluence &&
        !isSteadiness &&
        isConscientiousness
      ) {
        return 'Analyzer';
      }
      if (
        isDominance &&
        !isInfluence &&
        !isSteadiness &&
        !isConscientiousness
      ) {
        return 'Conductor';
      }
      if (
        !isDominance &&
        isInfluence &&
        !isSteadiness &&
        !isConscientiousness
      ) {
        return 'Promotor';
      }
      if (isDominance && isConscientiousness) {
        return 'Organizer';
      }
      if (isDominance && isInfluence) {
        return 'Motivator';
      }
      if (isDominance && isSteadiness) {
        return 'Stabilizer';
      }
      if (isInfluence && isConscientiousness) {
        return 'Strategist';
      }
      if (isInfluence && isSteadiness) {
        return 'Facilitator';
      }
      if (isConscientiousness && isSteadiness) {
        return 'Coordinator';
      }

      return 'No matching case';
    }
    return '';
  }, [discData]);
  return (
    <>
      {!userInfoData?.user_prospect?.used_disc && (
        <SummarizeCard
          icon="DiscIcon"
          cardData={[]}
          cardType="tag"
          description={
            <>
              Understand{' '}
              <Text variant="span" color="text-textPrimary" weight={400}>
                personality traits
              </Text>{' '}
              to better communication strategies
            </>
          }
          loading={isLoadingDisc}
          buttonText={DISCLabels.DISC_SUMMARY_BUTTON}
          onButtonClick={() => {
            fetchDisc();
          }}
        />
      )}
      {userInfoData?.user_prospect?.used_disc &&
        discData?.disc_profile?.trait1 && (
          <>
            <CardContainer title={DISCLabels.ACCORDION_TITLE}>
              <Flex direction="col">
                <Flex gap="gap-3" margin="mt-4">
                  <DiscIcon
                    data={[
                      discData?.disc_profile?.trait1?.name,
                      discData?.disc_profile?.trait2?.name,
                    ]}
                  />
                  <Flex direction="col">
                    <Flex gap="gap-2.5" alignItems="center">
                      <Text variant="plg" weight={500}>
                        {discTitle}
                      </Text>
                      <Flex>
                        <Text
                          variant="psm"
                          position="center"
                          color="text-primary"
                          weight={500}
                          className=" cursor-pointer"
                          onClick={() => {
                            window.open(
                              `https://www.discprofile.com/what-is-disc`,
                            );
                          }}
                        >
                          {CommonLabels.LEARN_MORE}
                          <ArrowRightOutlined rotate={-45} className="ml-2" />
                        </Text>
                      </Flex>
                    </Flex>
                    <Text margin=" mt-0" color="text-secondary">
                      {discTitleForm}
                    </Text>
                  </Flex>
                </Flex>
                {discData?.disc_profile?.trait1 && (
                  <AnalysisItem
                    title={discData?.disc_profile?.trait1?.name}
                    description={discData?.disc_profile?.trait1?.reason}
                    isLast={!discData?.disc_profile?.trait2?.name}
                  />
                )}
                {discData?.disc_profile?.trait2 && (
                  <AnalysisItem
                    title={discData?.disc_profile?.trait2?.name}
                    description={discData?.disc_profile?.trait2?.reason}
                    isLast
                  />
                )}
              </Flex>
            </CardContainer>

            <CardContainer title={DISCLabels.ACCORDION_PERSONALITY}>
              <Flex className="mt-5" width="w-full" direction="col">
                <AnalysisProgressContainer
                  title={DISCLabels.PERSONALITY_PARAMTER_1}
                  personalityParameter={
                    discData?.disc_profile?.personality_parameters
                      ?.risk_appetite?.value
                  }
                  parameterTooltip={
                    discData?.disc_profile?.personality_parameters
                      ?.risk_appetite?.reason
                  }
                />
                <AnalysisProgressContainer
                  title={DISCLabels.PERSONALITY_PARAMTER_2}
                  personalityParameter={
                    discData?.disc_profile?.personality_parameters?.fast_paced
                      ?.value
                  }
                  parameterTooltip={
                    discData?.disc_profile?.personality_parameters?.fast_paced
                      ?.reason
                  }
                />
                <AnalysisProgressContainer
                  title={DISCLabels.PERSONALITY_PARAMTER_3}
                  personalityParameter={
                    discData?.disc_profile?.personality_parameters?.independent
                      ?.value
                  }
                  parameterTooltip={
                    discData?.disc_profile?.personality_parameters?.independent
                      ?.reason
                  }
                />
                <AnalysisProgressContainer
                  title={DISCLabels.PERSONALITY_PARAMTER_4}
                  personalityParameter={
                    discData?.disc_profile?.personality_parameters?.optimistic
                      ?.value
                  }
                  parameterTooltip={
                    discData?.disc_profile?.personality_parameters?.optimistic
                      ?.reason
                  }
                />
                <AnalysisProgressContainer
                  title={DISCLabels.PERSONALITY_PARAMTER_5}
                  personalityParameter={
                    discData?.disc_profile?.personality_parameters?.trusting
                      ?.value
                  }
                  parameterTooltip={
                    discData?.disc_profile?.personality_parameters?.trusting
                      ?.reason
                  }
                />
              </Flex>
            </CardContainer>
            {typeof discData?.disc_profile?.dos_for_communicating ===
              'object' &&
              discData?.disc_profile?.dos_for_communicating?.length !== 0 && (
                <CardContainer
                  title={
                    <Flex alignItems="center" gap="gap-1">
                      <Icon name="CheckOutlined" className="" />
                      {DISCLabels.ACCORDION_DOS_COMMUNICATING}
                    </Flex>
                  }
                >
                  <ul className=" mb-0 text-sm font-normal text-textPrimary pl-5">
                    {discData?.disc_profile?.dos_for_communicating?.map(
                      (communicating, communicatingIndex) => {
                        return (
                          <li
                            key={`dos_for_communicating_${communicatingIndex}`}
                          >
                            {communicating}
                          </li>
                        );
                      },
                    )}
                  </ul>
                </CardContainer>
              )}
            {typeof discData?.disc_profile?.donts_for_communicating ===
              'object' &&
              discData?.disc_profile?.donts_for_communicating?.length !== 0 && (
                <CardContainer
                  title={
                    <Flex alignItems="center" gap="gap-1">
                      <Icon name="CloseOutlined" className="" />
                      {DISCLabels.ACCORDION_DONT_COMMUNICATING}
                    </Flex>
                  }
                >
                  <Flex>
                    <ul className=" mb-0 text-sm font-normal text-textPrimary pl-5">
                      {discData?.disc_profile?.donts_for_communicating?.map(
                        (communicating, communicatingIndex) => {
                          return (
                            <li
                              key={`donts_for_communicating_${communicatingIndex}`}
                            >
                              {communicating}
                            </li>
                          );
                        },
                      )}
                    </ul>
                  </Flex>
                </CardContainer>
              )}
          </>
        )}
      {((discData?.disc_profile || []).length === 0 ||
        !discData?.disc_profile?.trait1) &&
        userInfoData?.user_prospect?.used_disc &&
        !isLoadingDisc && <FailsEmptyState />}
    </>
  );
};

export default DiscInsights;
