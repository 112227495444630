import React, { useContext, useMemo, useState } from 'react';
import { Tag, Typography } from 'antd';
import Flex from 'components/Flex';
import Text from 'components/Text';
import SummarizeCard from 'components/SummarizeCard';
import Copy from 'components/Icon/Copy';
import Loading from 'components/Loading';
import FailsEmptyState from 'components/FailsEmptyState';
import { ActionsContext } from './actions.context';

// const IceBreakersLoadingComponent = () => {
//   return (
//     <Flex width="w-full" direction="col">
//       <Skeleton.Input size="small" className="w-full mb-1 rounded-3xl" active />
//       <Skeleton.Input size="small" active className="w-10/12 rounded-3xl" />

//       <Flex
//         width="w-full"
//         justify="between"
//         className="mt-2.5"
//         alignItems="center"
//         padding="pb-4"
//       >
//         <Flex width="w-1/4">
//           <Skeleton.Input
//             size="small"
//             className="w-full min-w-full rounded-3xl"
//             active
//           />
//         </Flex>
//         <Flex width="w-1/4">
//           <Skeleton.Input
//             size="small"
//             className="w-full min-w-full rounded-3xl"
//             active
//           />
//         </Flex>
//       </Flex>
//     </Flex>
//   );
// };

const IceBreakersComponent = ({
  text,
  category = '',
}: {
  text: string;
  category: string;
}) => {
  return (
    <Flex width="w-full" direction="col" gap="gap-2.5">
      <Flex justify="between" gap="gap-2" alignItems="center" width="w-full">
        <Tag
          className=" text-purple-800 bg-purple-100 font-medium rounded-3xl px-2.5 py-0.5 whitespace-pre-line"
          bordered={false}
        >
          {`${category}`}
        </Tag>
        <Flex
          justify="between"
          // className="mt-2.5"
          alignItems="center"
          // padding="pb-4"
        >
          {/* <Flex gap="gap-5">
          <LikeOutlined className="text-xl text-secondary hover:text-primary cursor-pointer" />
          <DislikeOutlined className=" text-xl text-secondary hover:text-primary cursor-pointer" />
        </Flex> */}

          <Typography.Paragraph
            className="mb-0 flex"
            copyable={{
              text,
              icon: [
                <Copy iconClassName="w-5" isShowLabel={false} />,
                <Copy iconClassName=" text-primary w-5" isShowLabel={false} />,
              ],
            }}
          />
        </Flex>
      </Flex>
      <Text
        variant="psm"
        color="text-textPrimary"
        margin="mb-4 mt-0"
        className="leading-5"
      >
        {text}
      </Text>
    </Flex>
  );
};

const IceBreakers = ({ data }: { data: any }) => {
  const [isOpen] = useState(true);
  return (
    <Flex
      padding="px-4"
      backgroundColor="bg-white"
      rounded="rounded-md"
      width="w-full"
      direction="col"
      className=" shadow-card"
    >
      {/* <Accordion
        color="text-textPrimary"
        titleClassName="!text-base"
        title={ActionsLabels.ICE_BREAKERS}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      /> */}
      {isOpen && (
        <Flex margin="mt-4" gap="gap-4" direction="col">
          {/* <IceBreakersLoadingComponent /> */}
          {(data || []).map((iceBreaker, iceBreakersIndex: number) => {
            return (
              <Flex
                direction="col"
                width="w-full"
                key={`icebreaker-${iceBreakersIndex}`}
              >
                <IceBreakersComponent
                  text={iceBreaker?.title || ''}
                  category={iceBreaker?.category || ''}
                />
                {data.length !== iceBreakersIndex + 1 && (
                  <Flex
                    border="border-0 border-solid border-t"
                    borderColor="border-gray-100"
                  />
                )}
              </Flex>
            );
          })}
          {/* {(data?.organization || []).map(
            (iceBreaker, iceBreakersIndex: number) => {
              return (
                <Flex
                  direction="col"
                  width="w-full"
                  key={`icebreaker-org-${iceBreakersIndex}`}
                >
                  <IceBreakersComponent
                    text={iceBreaker?.title || ''}
                    category={iceBreaker?.category}
                  />
                  {(data?.organization || []).length !==
                    iceBreakersIndex + 1 && (
                    <Flex
                      border="border-0 border-solid border-t"
                      borderColor="border-gray-100"
                    />
                  )}
                </Flex>
              );
            },
          )} */}
        </Flex>
      )}
    </Flex>
  );
};

const IcebreakersView = () => {
  const {
    isLoadingInsights,
    userInfoData,
    fetchCompanyInsights,
    fetchPersonInsights,
    iceBreakersData,
    isLoadingCompanyInsights,
  } = useContext(ActionsContext);
  // eslint-disable-next-line consistent-return
  const isShowSummary = useMemo(() => {
    if (
      !userInfoData?.user_prospect?.used_prospect_icebreaker &&
      !userInfoData?.user_prospect?.used_organization_icebreaker
    ) {
      return true;
    }
    // if (
    //   !userInfoData?.user_prospect?.used_prospect_icebreaker &&
    //   !userInfoData?.user_prospect?.used_organization_icebreaker
    // ) {
    //   if (!iceBreakersData?.length) {
    //     return true;
    //   }
    //   return false;
    // }
    // if (
    //   userInfoData?.user_prospect?.used_prospect_icebreaker &&
    //   !userInfoData?.user_prospect?.used_organization_icebreaker
    // ) {
    //   if (!iceBreakersData?.length) {
    //     return true;
    //   }
    //   return false;
    // }
    // if (
    //   !userInfoData?.user_prospect?.used_prospect_icebreaker &&
    //   !userInfoData?.user_prospect?.used_organization_icebreaker
    // ) {
    //   return true;
    // }
    // if (
    //   userInfoData?.user_prospect?.used_prospect_icebreaker &&
    //   userInfoData?.user_prospect?.used_organization_icebreaker
    // ) {
    //   return false;
    // }
  }, [iceBreakersData, userInfoData]);
  return (
    <>
      {(isShowSummary || isLoadingInsights) && (
        <SummarizeCard
          icon="PersonInsightIcon"
          cardData={[]}
          cardType="tag"
          description={
            <>
              Engage your prospects with our handpicked{' '}
              <Text variant="span" color="text-textPrimary" weight={400}>
                conversation starters
              </Text>
            </>
          }
          buttonText="Generate Icebreakers"
          onButtonClick={() => {
            // if (!userInfoData?.user_prospect?.used_prospect_icebreaker) {
            fetchPersonInsights();
            fetchCompanyInsights();
            // }
          }}
          loading={isLoadingInsights}
        />
      )}
      {/* {isLoadingInsights && (
      <InsightLoading title={InsightsLabels.LOADING_ICEBREAKERS} />
    )} */}
      {!isLoadingInsights &&
        userInfoData?.user_prospect?.used_prospect_icebreaker &&
        (!!iceBreakersData?.length ||
          !!userInfoData?.orignization_data?.organization_icebreaker) && (
          <IceBreakers data={iceBreakersData} />
        )}
      {(!userInfoData?.user_prospect?.used_organization_icebreaker ||
        !userInfoData?.user_prospect?.used_prospect_icebreaker) &&
        !isShowSummary && (
          <Flex
            width="w-full"
            justify="between"
            alignItems="center"
            className=" cursor-pointer"
            margin="mt-5"
            onClick={() => {
              if (!userInfoData?.user_prospect?.used_prospect_icebreaker) {
                fetchPersonInsights();
              } else {
                fetchCompanyInsights();
              }
            }}
          >
            <Text variant="psm" color="text-primary">
              Show more
            </Text>
            {isLoadingCompanyInsights && <Loading />}
          </Flex>
        )}
      {userInfoData?.user_prospect?.used_organization_icebreaker &&
        iceBreakersData?.length === 0 &&
        userInfoData?.user_prospect?.used_prospect_icebreaker &&
        !isLoadingInsights && <FailsEmptyState />}
      {/* {userProspectData?.icebreakers?.person?.status === 'failed' &&
      userProspectData?.icebreakers?.organization?.status ===
        'failed' && <RetryAccordion title={ActionsLabels.ICE_BREAKERS} />} */}
    </>
  );
};
export default IcebreakersView;
