/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useContext, useMemo } from 'react';
import { InsightsLabels } from 'consts/label';
import SummarizeCard from 'components/SummarizeCard';
import FailsEmptyState from 'components/FailsEmptyState';
import { NewsComponent, NewsLoading } from 'components/Page/News';
import Text from 'components/Text';
import { InsightsContext } from './insights.context';

const CompanyNewsInsights = () => {
  const {
    isGetNewsLoading,
    userInfoData,
    fetchNews,
    isLoadingCompanyInsights,
    organizationInsightsData,
  } = useContext(InsightsContext);
  const newsData = useMemo(
    () => [
      ...((userInfoData?.orignization_data || userInfoData?.organization_data)
        ?.news_articles || []),
      ...(organizationInsightsData || []).map((insight) => {
        const localInsights = insight;
        localInsights.isInsights = true;
        return localInsights;
      }),
    ],
    [
      userInfoData?.organization_data?.news_articles,
      userInfoData?.orignization_data?.news_articles,
      organizationInsightsData,
    ],
  );
  // const newsData = useMemo(() => {
  //   return (userInfoData?.orignization_data?.news_articles || []).map(
  //     (news) => {
  //       const localNewsData = { ...news };
  //       // const [maxFieldName] = Object.entries(news?.sentiment_stats).reduce(
  //       //   (acc, [fieldName, value]) =>
  //       //     Number.parseFloat(value as string) >
  //       //     Number.parseFloat(acc[1] as string)
  //       //       ? [fieldName, value]
  //       //       : acc,
  //       //   ['', Number.NEGATIVE_INFINITY],
  //       // );
  //       // localNewsData.sentitment = maxFieldName;
  //       return localNewsData;
  //     },
  //   );
  // }, [userInfoData?.orignization_data?.news_articles]);

  return (
    <>
      {!userInfoData?.user_prospect?.used_news &&
        !isGetNewsLoading &&
        !isLoadingCompanyInsights && (
          <SummarizeCard
            icon="CompanyIcon"
            cardType="tag"
            cardData={[]}
            description={
              <>
                Gain{' '}
                <Text variant="span" color="text-textPrimary" weight={400}>
                  news & intel
                </Text>{' '}
                to approach your connections with relevance
              </>
            }
            buttonText="Generate Company Insights"
            onButtonClick={() => {
              fetchNews();
            }}
            loading={isGetNewsLoading || isLoadingCompanyInsights}
          />
        )}
      {(isGetNewsLoading || isLoadingCompanyInsights) && (
        <NewsLoading title={InsightsLabels.LOADING_NEWS_ORG} />
      )}

      {!isGetNewsLoading &&
        !isLoadingCompanyInsights &&
        !!newsData?.length &&
        (userInfoData?.user_prospect?.used_news ||
          userInfoData?.user_prospect?.used_organization_insight) && (
          <NewsComponent
            title={`${InsightsLabels.INSIGHTS_FOR} ${
              userInfoData?.main_organization || ''
            }`}
            data={newsData}
          />
        )}
      {(newsData || [])?.length === 0 &&
        !isGetNewsLoading &&
        !isLoadingCompanyInsights &&
        (userInfoData?.user_prospect?.used_news ||
          userInfoData?.user_prospect?.used_organization_insight) && (
          <FailsEmptyState />
        )}
    </>
  );
};
export default CompanyNewsInsights;
