import { useCallback, useContext, useEffect, useState } from 'react';
import type { MenuProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import NavContext from 'context/NavContext';
import CreditPointsContext from 'context/CreditContext';
import {
  useGetProspectData,
  useGetUserInfo,
  useUpdateCreditPoints,
  useUnlockCredits,
  useGetUserProspectProspectInsightsIcebreakers,
  useGetUserProspectOrganizationInsightsIcebreakers,
  useGetUserProspectDiscProfile,
  useAddProspect,
} from './contact.query';

const ContactService = () => {
  const [outReachCount] = useState(1);
  const customerNav = useContext(NavContext);
  const {
    onRefetchCreditPoints,
    creditPointsData,
    prospectRefreshData,
    setProspectRefreshData,
    setIsShowRefresh,
    isLoadingRetryProspectRefetch,
    userInfo: loggedUserInfo,
    isAuthUserInfoLoading,
    onOpenGuide,
  } = useContext(CreditPointsContext);
  const [isShowContext, setIsShowContext] = useState(false);
  const [isLoadingProspectData] = useState(false);
  const [isUserInfoLoading, setIsUserInfoLoading] = useState(true);
  const [userInfoData, setUserInfoData] = useState<any>({});
  const [userProspectData, setUserProspectData] = useState<any>({});

  const [selectedTab, setSelectedTab] = useState('leads');
  const [isLoadingMoreEmails, setIsLoadingMoreEmails] = useState(false);
  const [activeKey, setActiveKey] = useState('company');
  const [isShowAll, setIsShowAll] = useState(false);
  const [isUserError, setIsUserError] = useState(false);
  const [isLoadingPersonInsights, setIsLoadingPersonInsights] = useState(false);
  const [prospectId, setProspectId] = useState('');
  const [isAddedToProspect, setIsAddedToProspect] = useState(false);
  const [prospectIceBreakersData, setProspectIceBreakersData] = useState<any>(
    {},
  );
  const [organizationIceBreakersData, setOrganizationIceBreakersData] =
    useState<any>({});
  const [discProfileData, setDiscProfileData] = useState<any>({});

  const searchParam = new URLSearchParams(window.location.search);
  const isAICoach = searchParam.get('isAICoach');
  useEffect(() => {
    if (isAICoach) {
      setActiveKey('person');
      setSelectedTab('people');
    }
  }, [isAICoach]);
  const [isLoadingCompanyInsights, setIsLoadingCompanyInsights] =
    useState(false);

  // const { mutate: onGetTechStack, isLoading: isTechStackLoading } =
  //   useGetTechStack();

  const { mutate: onGetUserInfo, isLoading: isGetUserInfoLoading } =
    useGetUserInfo();
  const { mutate: onGetProspectData, isLoading: isProspectDataLoading } =
    useGetProspectData();

  const { mutate: onUpdateCreditPoints, isLoading: isLoadingCreditPoints } =
    useUpdateCreditPoints();
  const { mutate: onUpdateCredit, isLoading: isLoadingUnlockCredit } =
    useUnlockCredits();
  const {
    mutate: onGetUserProspectIcebreakers,
    isLoading: isUserProspectIcebreakersAPILoading,
    // data: { data: insightsData } = {},
  } = useGetUserProspectProspectInsightsIcebreakers();
  const {
    mutate: onGetCompanyIcebreakers,
    isLoading: isCompanyIcebreakersAPILoading,
    // data: { data: insightsData } = {},
  } = useGetUserProspectOrganizationInsightsIcebreakers();
  const {
    mutate: onGetUserProspectDiscProfile,
    // isLoading: isUserProspectDiscProfileAPILoading,
    // data: { data: insightsData } = {},
  } = useGetUserProspectDiscProfile();
  const {
    mutate: onAddUserProspect,
    isLoading: isLoadingAddProspect,
    // data: { data: insightsData } = {},
  } = useAddProspect();

  const navigate = useNavigate();

  useEffect(() => {
    if (!customerNav.isProfile && customerNav.currentURL) {
      navigate('/onboarding');
    }
  }, [customerNav, customerNav.currentURL]);

  const handleMenuClick: MenuProps['onClick'] = useCallback(
    (e) => {
      if (e.key === 'outreach') navigate('/outreach-sequence');
    },
    [navigate],
  );
  const logout = () => {
    // localStorage.clear();
    localStorage.removeItem('token');
    localStorage.removeItem('userid');
    window.parent.postMessage({ type: 'logout' }, '*');
    if (localStorage.getItem('language')) {
      navigate('/auth');
    } else {
      navigate('/');
    }
  };

  const fetchUserData = () => {
    setIsUserError(false);
    onGetUserInfo(
      {
        linkedin_url: customerNav?.currentURL,
        // email_address: '',
      },
      {
        onSuccess: (data) => {
          setProspectId(data?.data?.user_prospect?.prospect_id);
          setUserInfoData(data?.data);
          // TODO: while showing email in about, refetch until status is pending
          if (!data?.data?.first_name && data?.data?.status !== 'failed') {
            setTimeout(() => {
              fetchUserData();
            }, 1000);
          } else {
            setTimeout(() => {
              onOpenGuide(true);
            }, 100);
            setIsUserInfoLoading(false);
          }
        },
        onError(error: any) {
          if (error?.response?.status === 401) {
            logout();
          } else if (error?.response?.status === 404) {
            setIsUserError(true);
          }
        },
      },
    );
  };
  const fetchUserInfo = () => {
    setIsUserInfoLoading(true);
    fetchUserData();
  };

  const onFetchProspectOrganisationInsightDetails = (prospect_id: string) => {
    onGetUserProspectIcebreakers(
      { prospect_id: prospect_id || prospectId },
      {
        onSuccess: (data) => {
          setProspectIceBreakersData(data?.data?.[0] || {});
        },
      },
    );
    onGetCompanyIcebreakers(
      { prospect_id: prospect_id || prospectId },
      {
        onSuccess: (data) => {
          setOrganizationIceBreakersData(data?.data?.[0] || {});
        },
      },
    );
  };
  const onFetchProspectDiscDetails = (prospect_id: string) => {
    onGetUserProspectDiscProfile(
      { prospect_id: prospect_id || prospectId },
      {
        onSuccess: (data) => {
          setDiscProfileData(data?.data?.[0] || {});
        },
      },
    );
  };

  useEffect(() => {
    if (prospectId) {
      onFetchProspectOrganisationInsightDetails(prospectId);
      onFetchProspectDiscDetails(prospectId);
    }
  }, [prospectId]);

  useEffect(() => {
    if (
      customerNav?.currentURL &&
      customerNav?.isExtensionOpen &&
      customerNav?.isProfile
    ) {
      setUserInfoData({});
      setProspectRefreshData({});
      fetchUserInfo();
      if (isAICoach) {
        setSelectedTab('people');
      } else {
        setSelectedTab('leads');
      }
      onRefetchCreditPoints();
    }
    if (customerNav?.isExtensionOpen) onOpenGuide(false);
  }, [
    customerNav?.currentURL,
    customerNav?.isExtensionOpen,
    customerNav?.isProfile,
    isAICoach,
  ]);

  const validateAddContextAction = () => {
    return (
      !userProspectData.insights ||
      userProspectData?.insights?.person?.status === 'pending' ||
      userProspectData?.insights?.organization?.status === 'pending' ||
      (userProspectData?.insights?.person?.data?.length === 0 &&
        userProspectData?.insights?.organization?.data?.length === 0)
    );
  };

  const viewMoreEmails = () => {
    setIsLoadingMoreEmails(true);
    onGetProspectData(
      {
        linkedin_url: customerNav?.currentURL,
        email_address: '',
      },
      {
        onSuccess: (data) => {
          setUserProspectData(data?.data);
          setIsLoadingMoreEmails(false);
        },
      },
    );
  };

  const handleTabChange = useCallback((value: string) => {
    setActiveKey(value);
  }, []);

  const handleUnlockCredit = (callback?: () => void) => {
    const userCreditData =
      creditPointsData?.data?.data || creditPointsData?.data;
    if (userCreditData?.available_credits > 0) {
      onUpdateCredit(
        {
          prospect_id: userInfoData?.user_prospect?.prospect_id,
        },
        {
          onSuccess(data) {
            if (data?.data?.status) {
              onUpdateCreditPoints(
                {
                  used_for: 'Unlock User Credits',
                  credit_used: 1, // Email and message credits
                },
                {
                  onSuccess: (creditData) => {
                    if (creditData?.data?.code === 200) {
                      onRefetchCreditPoints();
                    }
                    if (callback) {
                      callback();
                    }
                  },
                },
              );
              fetchUserData();
            }
          },
        },
      );
    }
  };

  useEffect(() => {
    if (prospectRefreshData) {
      setUserInfoData(prospectRefreshData);
    }
  }, [prospectRefreshData]);

  const handleAddToProspect = () => {
    onAddUserProspect(
      {
        prospect_ids: [userInfoData?.user_prospect?.prospect_id],
      },
      {
        onSuccess: () => {
          fetchUserData();
        },
      },
    );
  };
  return {
    outReachCount,
    handleMenuClick,
    isLoadingUserInfo: isUserInfoLoading || isAuthUserInfoLoading,
    userInfoData,
    isLoadingProspectData: isProspectDataLoading || isLoadingProspectData,
    userProspectData,
    isShowContext,
    setIsShowContext,
    customerNav,
    validateAddContextAction,
    selectedTab,
    setSelectedTab,
    viewMoreEmails,
    isLoadingMoreEmails,
    handleTabChange,
    activeKey,
    isShowAll,
    setIsShowAll,
    creditPoints: creditPointsData?.data?.data || creditPointsData?.data,
    isCreditPointsLoading: isLoadingCreditPoints || isLoadingUnlockCredit,
    handleUnlockCredit,
    fetchUserData,
    setIsLoadingPersonInsights,
    setIsLoadingCompanyInsights,
    isLoadingInsights: isLoadingPersonInsights || isLoadingCompanyInsights,
    isLoadingCompanyInsights,
    isUserError,
    setIsShowRefresh,
    isLoadingRetryProspectRefetch,
    loggedUserInfo,
    isGetUserInfoLoading,
    isAuthUserInfoLoading,
    iceBreakersData: [
      ...(prospectIceBreakersData?.prospect_icebreaker || []),
      ...(organizationIceBreakersData?.organization_icebreaker || []),
    ],
    prospectInsightsData: prospectIceBreakersData?.prospect_insight || [],
    organizationInsightsData:
      organizationIceBreakersData?.organization_insight || [],
    discData: discProfileData,
    onFetchProspectOrganisationInsightDetails,
    onFetchProspectDiscDetails,
    handleAddToProspect,
    isLoadingApiAddProspect: isLoadingAddProspect,
    isLoadingAddProspect: isLoadingAddProspect || isGetUserInfoLoading,
    isUserIcebreakerLoading:
      isUserProspectIcebreakersAPILoading || isCompanyIcebreakersAPILoading,
    isAddedToProspect,
    setIsAddedToProspect,
  };
};
export default ContactService;
