import React, { useContext, useEffect, useMemo, useState } from 'react';
import { avatarMap } from 'utils';
// import NavContext from 'context/NavContext';
import { ContactLabels } from 'consts/label';
import { Avatar, Skeleton, Tag, Tooltip, Typography } from 'components/Ant';
import Text from 'components/Text';
import UserInfoComponent from 'components/UserInfo';
import Flex from 'components/Flex';
import { Icon } from 'components/Icon';
import Loading from 'components/Loading';
// eslint-disable-next-line import/no-relative-parent-imports
import { ContactContext } from '../contact.context';

const AboutView = () => {
  const {
    userInfoData,
    isLoadingUserInfo,
    isShowAll,
    setIsShowAll,
    // loggedUserInfo,
    handleAddToProspect,
    isLoadingAddProspect,
    isAddedToProspect,
    setIsAddedToProspect,
    isLoadingApiAddProspect,
  } = useContext(ContactContext);
  const [isShowDescEllipsis, setShowDescEllipsis] = useState(true);
  const [isEnabledDescEllipsis, setEnabledDescEllipsis] = useState(false);
  const searchParam = new URLSearchParams(window.location.search);
  const isWebApp = searchParam.get('isWebApp');
  // const customerNav = useContext(NavContext);

  useEffect(() => {
    if (isLoadingAddProspect && isLoadingApiAddProspect) {
      setIsAddedToProspect(true);
    }
    if (!isLoadingAddProspect && isAddedToProspect) {
      setTimeout(() => {
        setIsAddedToProspect(false);
      }, 2000);
    }
  }, [isLoadingAddProspect, isLoadingApiAddProspect]);
  const specialities = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (userInfoData?.orignization_data?.specialities || []).filter(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      (data) => data,
    );
  }, [userInfoData?.orignization_data?.specialities]);
  return (
    <>
      {(userInfoData?.first_name || isLoadingUserInfo) && (
        <Flex
          backgroundColor="bg-white"
          padding="p-5 pt-6"
          rounded="rounded-md"
          className="shadow-card"
          gap="gap-5"
          direction="col"
          alignItems="start"
        >
          {isLoadingUserInfo && !userInfoData?.first_name && (
            <Flex gap="gap-4" alignItems="center" justify="center">
              <Skeleton.Avatar size={54} />
              <Skeleton.Input size="small" />
            </Flex>
          )}
          {userInfoData?.first_name && (
            <Flex gap="gap-4" alignItems="center" justify="center">
              <Avatar src={userInfoData?.profile_pic_url} size={54}>
                {avatarMap(userInfoData.first_name, 2)}
              </Avatar>
              <Text variant="h1" weight={500} color="text-gray-900">
                {`${userInfoData.first_name || ''} ${
                  userInfoData.last_name || ''
                }`}
              </Text>
            </Flex>
          )}
          <Flex direction="col" width="w-full">
            <UserInfoComponent
              icon="DesignationIcon"
              name={userInfoData?.role || userInfoData?.occupation || ''}
              isLoading={isLoadingUserInfo}
              placeholder={ContactLabels.DESIGNATION_PLACEHOLDER}
              rightChildren={
                <div className="ml-4">
                  {!isLoadingAddProspect && isAddedToProspect && (
                    <Flex alignItems="center" gap="gap-1">
                      <Icon
                        name="PasswordTickIcon"
                        className="text-green-500 w-2.5 h-2.5"
                      />
                      <Text
                        variant="psm"
                        weight={500}
                        className="text-green-500"
                      >
                        {ContactLabels.ADDED_TO_MY_PROSPECT}
                      </Text>
                    </Flex>
                  )}
                  {!userInfoData?.user_prospect?.is_prospect &&
                    !isLoadingUserInfo && (
                      <Tooltip
                        title={
                          isLoadingAddProspect || isAddedToProspect
                            ? null
                            : ContactLabels?.ADD_TO_MY_PROSPECT
                        }
                      >
                        <span
                          id="AboutAIPitch"
                          className={`flex items-center ${
                            isLoadingAddProspect
                              ? 'pointer-events-none'
                              : ' cursor-pointer'
                          }`}
                        >
                          {isLoadingAddProspect && <Loading />}
                          {!isLoadingAddProspect && !isAddedToProspect && (
                            <Flex
                              onClick={() => {
                                handleAddToProspect();
                              }}
                            >
                              <Text
                                variant="psm"
                                className="ml-1 whitespace-nowrap"
                                color="text-primary"
                              >
                                + Add
                              </Text>
                            </Flex>
                          )}
                        </span>
                      </Tooltip>
                    )}
                </div>
              }
            />
            <UserInfoComponent
              icon="CompanyIcon"
              name={userInfoData?.orignization_data?.name || ''}
              isLoading={isLoadingUserInfo}
              placeholder={ContactLabels.ORGANISATION_PLACEHOLDER}
            />
            {!isWebApp && (
              <>
                {isShowAll && (
                  <>
                    {isLoadingUserInfo && <Skeleton active />}
                    {userInfoData?.orignization_data?.description && (
                      <>
                        <Typography.Paragraph
                          ellipsis={
                            isShowDescEllipsis
                              ? {
                                  rows: 4,
                                  expandable: false,
                                  onEllipsis: (ellipsis) => {
                                    setEnabledDescEllipsis(ellipsis);
                                    setShowDescEllipsis(ellipsis);
                                  },
                                }
                              : false
                          }
                          className=" text-sm text-textPrimary whitespace-pre-line"
                        >
                          {userInfoData?.orignization_data?.description}
                        </Typography.Paragraph>
                        {isEnabledDescEllipsis && (
                          <span
                            role="none"
                            onClick={() => {
                              setShowDescEllipsis(!isShowDescEllipsis);
                            }}
                            className=" text-sm text-primary font-inter mb-3 cursor-pointer"
                          >
                            {isShowDescEllipsis ? 'More' : 'Less'}
                          </span>
                        )}
                      </>
                    )}
                    {(userInfoData?.orignization_data?.website ||
                      isLoadingUserInfo) && (
                      <UserInfoComponent
                        icon="WebsiteIcon"
                        name={
                          <Text
                            variant="span"
                            weight={400}
                            color="text-textPrimary"
                            className=" whitespace-break-spaces cursor-pointer"
                            onClick={() => {
                              window.open(
                                userInfoData?.orignization_data?.website || '',
                              );
                            }}
                          >
                            {userInfoData?.orignization_data?.website || ''}
                          </Text>
                        }
                        isLoading={isLoadingUserInfo}
                        placeholder=""
                      />
                    )}
                    {(userInfoData?.orignization_data?.industry ||
                      isLoadingUserInfo) && (
                      <UserInfoComponent
                        icon="CompanyIcon"
                        name={userInfoData?.orignization_data?.industry || ''}
                        isLoading={isLoadingUserInfo}
                        placeholder=""
                      />
                    )}
                    {(!!userInfoData?.orignization_data?.company_size?.length ||
                      isLoadingUserInfo) && (
                      <UserInfoComponent
                        icon="CompanyUserInfoIcon"
                        name={`${
                          isLoadingUserInfo
                            ? ''
                            : `${
                                userInfoData?.orignization_data
                                  ?.company_size?.[0] || ''
                              }+ employees`
                        }`}
                        isLoading={isLoadingUserInfo}
                        placeholder=""
                      />
                    )}
                    {(isLoadingUserInfo ||
                      userInfoData?.orignization_data?.follower_count) && (
                      <UserInfoComponent
                        icon="LinkedInFollowersIcon"
                        name={`${
                          userInfoData?.orignization_data?.follower_count
                            ? `${new Intl.NumberFormat('en-IN', {
                                maximumSignificantDigits: 3,
                              }).format(
                                userInfoData?.orignization_data
                                  ?.follower_count || 0,
                              )} on Linkedin`
                            : ''
                        }`}
                        isLoading={isLoadingUserInfo}
                        placeholder=""
                      />
                    )}
                    {(!!userInfoData?.orignization_data?.locations?.length ||
                      isLoadingUserInfo) && (
                      <UserInfoComponent
                        icon="LocationIcon"
                        name={
                          !isLoadingUserInfo
                            ? `${
                                userInfoData?.orignization_data?.locations?.[0]
                                  ?.city || ''
                              }, ${
                                userInfoData?.orignization_data?.locations?.[0]
                                  ?.country || ''
                              }`
                            : ''
                        }
                        isLoading={isLoadingUserInfo}
                        placeholder=""
                      />
                    )}
                    {isLoadingUserInfo && (
                      <UserInfoComponent
                        icon="RevenueIcon"
                        name={userInfoData?.main_role || ''}
                        isLoading={isLoadingUserInfo}
                        placeholder=""
                      />
                    )}
                    {(!!specialities?.length || isLoadingUserInfo) && (
                      <UserInfoComponent
                        icon="SassIcon"
                        name={
                          isLoadingUserInfo
                            ? ''
                            : specialities.map(
                                (speciality, specialitiesIndex) => {
                                  return (
                                    <Tag
                                      className="  font-medium text-gray-800 px-3 bg-gray-100 border-0 rounded-3xl break-words whitespace-pre-line mb-1"
                                      key={`specialities-${specialitiesIndex}`}
                                    >
                                      {speciality}
                                    </Tag>
                                  );
                                },
                              )
                        }
                        isLoading={isLoadingUserInfo}
                        placeholder=""
                      />
                    )}
                  </>
                )}
                {!isLoadingUserInfo && (
                  <>
                    <Flex
                      alignItems="center"
                      justify="center"
                      margin="mt-2"
                      position="absolute"
                      className="text-secondary hover:text-primary -bottom-8  cursor-pointer left-0 right-0  "
                      onClick={() => {
                        if (!isLoadingUserInfo) setIsShowAll(!isShowAll);
                      }}
                    >
                      <Icon
                        name="ArrowIcon"
                        className={`scale-100 skew-x-0 skew-y-0 translate-x-0 translate-y-0 border hover:border-primary border-solid bg-white border-silver rounded-full ${
                          isShowAll ? 'rotate-180' : ''
                        }`}
                      />
                    </Flex>
                  </>
                )}
                {/* {isLoadingUserInfo ? (
                  <Skeleton.Button block className="mt-2" active />
                ) : (
                  <Button
                    onClick={() => {
                      setIsShowAll(!isShowAll);
                    }}
                    className="mt-2 text-secondary hover:text-primary"
                  >
                    <Flex alignItems="center" justify="center">
                      {isShowAll ? `Close` : 'View more'}
                      <Icon
                        name="ArrowIcon"
                        className={` ml-1 scale-100 skew-x-0 skew-y-0 translate-x-0 translate-y-0 ${
                          isShowAll ? 'rotate-180' : ''
                        }`}
                      />
                    </Flex>
                  </Button>
                )} */}
              </>
            )}
          </Flex>
        </Flex>
      )}
    </>
  );
};
export default AboutView;
