import { useContext, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-relative-parent-imports
import { ContactContext } from '../contact.context';
import {
  useGetCompanyInsights,
  useGetGenericDisc,
  useGetInsights,
  useGetKeyChallengesInfo,
  useGetNewsData,
  useGetYoutubeData,
} from './insights.query';

export default function InsightService() {
  const {
    userInfoData,
    fetchUserData,
    isLoadingProspectData,
    isLoadingInsights,
    setIsLoadingPersonInsights,
    isLoadingCompanyInsights,
    setIsLoadingCompanyInsights,
    handleUnlockCredit,
    isGetUserInfoLoading,
    prospectInsightsData,
    organizationInsightsData,
    discData,
    onFetchProspectOrganisationInsightDetails,
    onFetchProspectDiscDetails,
  } = useContext(ContactContext);
  const [isGetKeyChallengesLoading, setIsGetKeyChallengesLoading] =
    useState(false);
  const [isGetDiscLoading, setIsGetDiscLoading] = useState(false);
  const [isGetNewsLoading, setIsGetNewsLoading] = useState(false);
  const [isGetYoutubeLoading, setIsGetYoutubeLoading] = useState(false);
  const {
    mutate: onGetInsights,
    isLoading: isInsightsLoading,
    // data: { data: insightsData } = {},
  } = useGetInsights();

  const {
    mutate: onGetCompanyInsights,
    isLoading: isCompanyInsightsLoading,
    // data: { data: insightsData } = {},
  } = useGetCompanyInsights();
  const {
    mutate: onGetGenericDisc,
    isLoading: isGetGenericLoading,
    // data: { data: insightsData } = {},
  } = useGetGenericDisc();

  const {
    mutate: onGetNewsData,
    isLoading: isGetNewsDataLoading,
    // data: { data: insightsData } = {},
  } = useGetNewsData();

  const {
    mutate: onGetYoutubeData,
    isLoading: isGetYoutubeDataLoading,
    // data: { data: insightsData } = {},
  } = useGetYoutubeData();

  const handleTrailUnlockCredit = (func) => {
    if (!userInfoData?.user_prospect?.credit) {
      handleUnlockCredit(func);
    } else {
      func();
    }
  };
  const { mutate: onGetKeyChallenges, isLoading: isKeyChallengesLoading } =
    useGetKeyChallengesInfo();

  useEffect(() => {
    if (!isGetUserInfoLoading && !isKeyChallengesLoading) {
      setIsGetKeyChallengesLoading(false);
    }
    if (!isGetUserInfoLoading && !isInsightsLoading) {
      setIsLoadingPersonInsights(false);
    }
    if (!isCompanyInsightsLoading && !isGetUserInfoLoading) {
      setIsLoadingCompanyInsights(false);
    }
    if (!isGetDiscLoading && !isGetUserInfoLoading) {
      setIsGetDiscLoading(false);
    }
    if (!isGetNewsDataLoading && !isGetUserInfoLoading) {
      setIsGetNewsLoading(false);
    }
    if (!isGetYoutubeDataLoading && !isGetUserInfoLoading) {
      setIsGetYoutubeLoading(false);
    }
  }, [
    isGetUserInfoLoading,
    isKeyChallengesLoading,
    isInsightsLoading,
    isCompanyInsightsLoading,
    isGetDiscLoading,
    isGetNewsDataLoading,
    isGetYoutubeDataLoading,
  ]);

  const onResponseError = (error: any) => {
    if (error?.response?.status === 404) {
      fetchUserData();
    }
  };

  const onUnlockYoutubeData = () => {
    onGetYoutubeData(
      {
        prospect_id: userInfoData?.user_prospect?.prospect_id,
        refetch: false,
      },
      {
        onSuccess() {
          fetchUserData();
        },
        onError(error: any) {
          onResponseError(error);
        },
      },
    );
  };

  const onUnlockPeronInsights = () => {
    onUnlockYoutubeData();
    onGetInsights(
      {
        prospect_id: userInfoData?.user_prospect?.prospect_id,
        api_for: 'Insight',
      },
      {
        onSuccess: () => {
          // fetchUserData();
          onFetchProspectOrganisationInsightDetails();
        },
        onError(error: any) {
          onResponseError(error);
        },
      },
    );
  };

  const fetchPersonInsights = () => {
    setIsLoadingPersonInsights(true);
    setIsGetYoutubeLoading(true);
    handleTrailUnlockCredit(onUnlockPeronInsights);
  };
  const onUnlockCompanyInsights = () => {
    onGetCompanyInsights(
      {
        prospect_id: userInfoData?.user_prospect?.prospect_id,
        api_for: 'Insight',
      },
      {
        onSuccess: () => {
          onFetchProspectOrganisationInsightDetails();
          fetchUserData();
        },
        onError(error: any) {
          onResponseError(error);
        },
      },
    );
  };
  const fetchCompanyInsights = () => {
    setIsLoadingCompanyInsights(true);
    handleTrailUnlockCredit(onUnlockCompanyInsights);
  };

  const onUnlockFetchKeyChallenges = () => {
    onGetKeyChallenges(
      {
        prospect_id: userInfoData?.user_prospect?.prospect_id,
      },
      {
        onSuccess() {
          fetchUserData();
        },
        onError(error: any) {
          onResponseError(error);
        },
      },
    );
  };

  const fetchKeyChallenges = () => {
    setIsGetKeyChallengesLoading(true);
    handleTrailUnlockCredit(onUnlockFetchKeyChallenges);
  };
  const onUnlockFetchDisc = () => {
    onGetGenericDisc(
      {
        prospect_id: userInfoData?.user_prospect?.prospect_id,
      },
      {
        onSuccess() {
          onFetchProspectDiscDetails();
          fetchUserData();
        },
        onError(error: any) {
          onResponseError(error);
        },
      },
    );
  };
  const fetchDisc = () => {
    setIsGetDiscLoading(true);
    handleTrailUnlockCredit(onUnlockFetchDisc);
  };

  const onUnlockFetchNews = () => {
    onUnlockCompanyInsights();
    onGetNewsData(
      {
        prospect_id: userInfoData?.user_prospect?.prospect_id,
        refetch: false,
      },
      {
        onSuccess() {
          fetchUserData();
        },
        onError(error: any) {
          onResponseError(error);
        },
      },
    );
  };

  const fetchNews = () => {
    setIsGetNewsLoading(true);
    setIsLoadingCompanyInsights(true);
    handleTrailUnlockCredit(onUnlockFetchNews);
  };

  return {
    isLoadingInsights: isLoadingInsights || isLoadingProspectData,
    userInfoData,
    fetchPersonInsights,
    fetchCompanyInsights,
    isLoadingCompanyInsights: isLoadingProspectData || isLoadingCompanyInsights,
    fetchKeyChallenges,
    isLoadingKeyChallenges:
      isLoadingProspectData ||
      isKeyChallengesLoading ||
      isGetKeyChallengesLoading,
    fetchDisc,
    isLoadingDisc: isGetGenericLoading || isGetDiscLoading,
    isGetNewsLoading,
    fetchNews,
    isGetYoutubeLoading,
    prospectInsightsData,
    organizationInsightsData,
    discData,
  };
}
